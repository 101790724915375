export enum Entity {
  // admin
  USER = 'User',
  ROLE = 'Role',
  ins_agent = 'Ins/Agent',
  // settings
  BRANCH = 'Branch',
  EMPLOYEE = 'Employee',
  commissionProperty = 'CommissionProperty',
  ins_receiptAdjAccount = 'Ins/ReceiptAdjAccount',

  // LRP
  EMPLOYEE_RISK_MANAGER = 'Employee/RiskManager',
  RISK_MANAGER_WITH_SALES = 'Employee/RiskManagerWithSales',
  INS_COMM_TYPE_SELECT = 'Ins/CompType/Select',

  // Select
  cme_additClearing_select = 'Cme/AdditClearing/Select',

  // monthly processing
  monthlyProcessing_receipt = 'monthlyProcessing/Receipt',

  manualEntry = 'manualEntry',

  manualEntryType = 'manualEntryType',

  // communications
  communication_template = 'communication/Template',

  // utilities
  utilities_pip = 'utilities/PIP',

  // SCE entry
  GrowerContacts = 'GrowerContact',
}

export enum CasAsOfMode {
  ViewEdit = 'ViewEdit',
  NewEntity = 'NewEntity',
  NewAsOf = 'NewAsOf',
}

export type IPropertyItem = { property: string; value: string };

export type IObject = { label: string; value: string };

export enum AsOfColor {
  Edit = '#ECF7FE',
  Add = '#F1FEEB',
  Disabled = '#EBEBEB',
  RateColor = '#f15bf1',
  DeleteColor = '#00B8D9',
}

export enum Colors {
  Past = '#EAEAEA', //  grey
  Present = '#FDE53E', //  yellow
  Future = '#FFFFFF', //  white
}
